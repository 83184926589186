.section-title{
    text-align: center;
    margin: 20px 0 50px 0;
    font-weight: 800;
    font-size: 40px;
    width: 100%;
    /* border: 1px solid; */
}
.unstyled-list{
    list-style: none;
    font-size: 30px;

}
.subDetails{
    padding: 0 50px 0 50px;
    font-size: 15px;
}
.fb-video{
    /* height: 200px; */
}
.swipperTitle{
    text-align: center;
    margin: 20px 0 50px 0;
    font-weight: 800;
    font-size: 30px;
    width: 100%;
}

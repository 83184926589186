@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

body {
  font-family: "Tajawal", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  overflow-x: hidden;
}

code {
  font-family: "Tajawal", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.more {
  background-color: #093EB6 !important;
  color: white !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.swiper-button-next:after {
  content: 'next';
  padding: 10px;
  height: 227px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #093EB661;
  font-size: 15px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  left: 40px;

}

.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 55px) !important;
  right: auto !important;
}

.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 55px) !important;
  left: auto !important;
}

.swiper-button-prev:after {
  content: 'next';
  padding: 10px;
  height: 227px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #093EB661;
  font-size: 15px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

[dir="rtl"] .titleBreadCrumb {
  color: #093EB6;
  font-size: 25px;
  font-weight: 900;
  margin-left: 20px
}
[dir="ltr"] .titleBreadCrumb {
  color: #093EB6;
  font-size: 25px;
  font-weight: 900;
  margin-right: 20px
}


@media(max-width:992px){
  /* .swiper-button-next:after {
    left: 20px;
  }
  .swiper-button-prev:after {
    left: 20px;
  } */
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 55px) !important;
    left: 0 !important;
  }
  
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 55px) !important;
    right: 0 !important;
  }
}
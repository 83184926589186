.about-us {
    overflow-x: hidden;
}

.content-about {
    width: 75%;
    margin-top: 10px
}

[dir="rtl"] .image-fatah {
    text-align: right;
}
[dir="ltr"] .image-fatah {
    text-align: left;
}

.image-fatah-footer {
    text-align: left;
}


/* Handle Contact Us */
.handleContact {

    border-radius: 20px;
    background-image: url(../assets/save.jfif);
    background-color: rgba(9, 62, 182, 0.9) !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    color: white !important;
    padding: 80px;
}

.contact-content-info{
    width: 75%;
}

@media(max-width:992px) {
    .content-about {
        width: 100%;
    }

    .contact-content-info {
        width: 100% !important;
    }

    .image-fatah, .image-fatah-footer {
        text-align: center;
    }

    .handleContact {
        padding: 20px;
    }
}
.header {
    width: 90%;
    margin: 20px auto !important;
    background-color: #F4F5F8;
    border: 1px solid;
    border-image-source: linear-gradient(250.12deg, #FFFFFF 36.72%, rgba(203, 203, 203, 0) 120.35%);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-items {
    display: flex;
    list-style: none;
}

.nav-items li {
    margin: 0 15px;
}

.nav-items a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s;
}

.nav-items a:hover {
    color: #0056b3;
    /* Hover color */
}

.nav-items a.active {
    color: blue;
    /* Active link color */
}

.right-nav {
    direction: rtl;
}

.left-nav {
    direction: ltr;
}

.logo img {}


/* Footer */
.footer {
    padding: 50px;
    margin-top: 80px;
}

.info-footer {
    width: 80%;
    margin: 20px auto;
    padding: 10px;
    background-color: #CA8C48;
    color: white;
    padding: 20px;

}

.content-footer {
    width: 80%;
    margin: 20px auto;
    background: linear-gradient(90deg, #FFFFFF 0%, #F4F5F8 100%);

}

.rights {
    width: 80%;
    margin: auto;
}

.list-footer {
    width: 100%;
}

[dir="rtl"] .list-footer ul {
    margin-right: auto;
    width: 50%;
}

[dir="ltr"] .list-footer ul {
    margin-left: auto;
    width: 50%;
}

/* [dir="rtl"] .list-footer ul {
    text-align: left;
}

[dir="ltr"] .list-footer ul {
    text-align: right;
} */



.list-footer li {
    list-style: none;
    margin-bottom: 10px;
}



.footer-bar {
    display: none;
    /* Hide footer bar on large screens */
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
    background-color: #F4F5F8 !important;
    z-index: 5000;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.footer-bar a {
    text-align: center;
    color: #000;
    text-decoration: none;
    font-size: 12px;
    flex: 1;
}

.navFooterBar {
    display: inline-block !important;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    line-height: 100%;


}

.iconArrow{
    /* margin-top:-75px !important; */
    padding:15px !important;
    border: 4px solid #F4F5F8;
    width:60px;
    height:60px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

/* .iconData{
    color:#616671 !important; 

} */
.arrowLocation {
    background-color: #093EB6;
}

.inactive-nav {
    color: #616671 !important;
    font-size: 16px !important;
}

[dir="rtl"] .nav-items {
    flex-direction: row;
}

[dir="ltr"] .nav-items {
    flex-direction: row-reverse;
}

.custom-dropdown {
    position: absolute;
    bottom: 100%;
    left: 100%;
    transform: translateX(-50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 10px 0;
    min-width: 200px;
}

.dropdown-item {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: #333;
    transition: background 0.2s;
    font-size: 20px;
}

.dropdown-item:hover {
    background: #f5f5f5;
}

.active {
    color: #093EB6 !important;
    font-weight: bolder;
    font-weight: 900 !important;
}

@media(max-width:768px) {
    .footer {
        padding: 20px;
    }

    .info-footer {
        width: 100%;
    }

    .content-footer {
        width: 100%;
    }

    .list-footer ul {
        text-align: right;
        margin-top: 50px;
    }

    .rights {
        width: 100%;
    }

    .right-info .infoFooterContact {
        display: block;
        margin-bottom: 15px;
    }

    .right-nav,
    .left-nav {
        display: none;
    }

    .logo {
        text-align: center;
        margin: auto;
    }

    .footer-bar {
        display: flex;
        justify-content: space-around;
    }



    [dir="rtl"] .list-footer ul {
        margin-right: 0;
        text-align: left;
        width: 50%;

    }

    [dir="ltr"] .list-footer ul {
        margin-left: 0;
        width: 50%;
        text-align: left;

    }


}
.caursolItem {
    position: relative;
}

.titleCaursol {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.titleCaursol p {
    color: white;
    font-size: 2rem;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.titleCaursol span {
    color: white;
    font-size: 16px;
    font-weight: 300;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Wrapper for positioning the image and animation */
.carousel-image-wrapper {
    position: relative;
    overflow: hidden;
}

.carousel-image {
    height: 90vh;
    width: 95%
}

/* Add animation to images */
.carousel-image {
    width: 100%;
    height: 90vh;
    transition: transform 1s ease, opacity 1s ease;
}

/* Animation effects when the image enters the carousel */
.carousel-item.active .carousel-image {
    transform: scale(1.1);
    /* Zoom-in effect */
    opacity: 1;
}

/* Animation effects for the next/previous images */
.carousel-item-next .carousel-image,
.carousel-item-prev .carousel-image {
    transform: scale(1);
    /* Reset zoom-out effect */
    opacity: 0.7;
    /* Slight fade for next/previous images */
}


/* Welcome Compnent */
.welcome-section {
    margin-top: 120px;
}

.welcome-section .image-container,
.welcome-section .image-container-child {
    overflow: hidden;
    position: relative;
}

.welcome-section .image-container img,
.welcome-section .image-container-child img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.welcome-section .image-container-child {
    margin-top: -120px;
    margin-right: -10px;
    width: 35%;
    z-index: 1;
}

.welcome-section .image-container:hover img,
.welcome-section .image-container-child:hover img {
    transform: scale(1.1);
}

.welcome-section .image-container .overlay, .welcome-section .image-container-child .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #093EB661;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
}

.welcome-section .image-container:hover .overlay, .welcome-section .image-container-child:hover .overlay {
    opacity: 1;
}



.welcome-text {
    padding: 10px;
    text-align: center;
    background-color: #CA8C48;
    color: white;
    font-weight: 500;
    font-size: 20px;
}

.infoWelcom ol {
    list-style-position: inside;
    list-style-type: decimal;
}

.childImage {
    margin-top: -150px;
    margin-right: -50px;
}

/* Service */

.services {
    margin-top: 200px;
    margin-bottom: 120px;
}


.services .service-title, .partner .service-title, .contact-us .contact-title {
    background-color: #CA8C48;
    color: white;
    width: 22%;
    margin: auto;
    text-align: center;
    padding: 10px;
    font-size: 25px;
    font-weight: 700;
}

.service-info {
    width: 50%;
    margin: 20px auto;
    font-size: 20px;

}

.serviceSlide {
    padding: 20px;
    background-color: red;
}

/* Who Us */
.who-title {
    color: white;
    background-color: #CA8C48;
    padding: 15px;
    width: 28%;
    text-align: center;
    font-weight: 500;
    font-size: 25px;
}

.who-us {
    background-color: #F4F5F8;
    padding: 20px;
}


/* Partner */
.partner {
    margin-top: 120px;
}


/* contact us */

.contact-us {
    margin-top: 300px !important;
    background-color: #F4F5F8;
    padding: 80px;
}

.form-contact-us {
    background-color: white;
    width: 40%;
    margin: 25px auto;
    padding: 15px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.inputs {
    background-color: #F4F5F8 !important;
}



@media(max-width:768px) {
    .welcome-section .parentImage {
        width: 100%;
    }

    .welcome-section .childImage {
        width: 50%;
    }

    .welcome-content {
        margin-top: 120px;
    }

    .childImage {
        margin-top: -100px;
        margin-right: 10px;
    }

    .services .service-title, .partner .service-title, .contact-us .contact-title, .who-us .who-title {
        width: 90%;
    }

    .service-info {
        width: 90%;
    }

    .form-contact-us {
        width: 100%;
    }

    .contact-us {
        padding: 20px;
    }

    /* .titleCaursol {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } */

    .titleCaursol p {
        color: white;
        font-size: 13px;
        font-weight: 600;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .titleCaursol span {
        color: white;
        font-size: 10px;
        font-weight: 300;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .carousel-image {
        height: 60vh;
        width: 95%
    }
    

}